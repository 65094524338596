@font-face {
    font-family: 'Adumu';
    src: url('../public/assets/fonts/adumu.ttf') format('truetype');
}



.talents{
    font-family: Montserrat;
}
.loginHeading, .loginHeading span {
    font-family: 'Adumu';
}
.loginHeading span{
    color: #FF9434;
}
html{
    margin: 0;
    padding: 0;
}
html *{
    font-family: Montserrat;
    box-sizing: border-box;
}
body *{
    font-family: Montserrat;
}
h1, h2, h3, h4, h5, h6{
    font-family: Montserrat;
}

.fieldBG{
    width: 100vw;
    min-height: 100vh;
    position: relative;
    height: auto;
    overflow: hidden;
    background-image: url('../public/images/hero-bg.png');
    background-position: center center ;
    background-size: cover;
    
}

.fieldBG:before{
        content: "";
        position: absolute;
        left: 0; right: 0;
        top: 0; bottom: 0;
        background: rgba(0, 0, 102, 0.665);
}
.fieldBG:after{
    content:"";
    position: absolute;
    background-image: url('../public/images/soccerball1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    bottom: 20px;
    width: 150px;
    height: 150px;
    right: -50px;
    z-index: 10000;
}

.container{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3%;
    /* justify-content: center; */
}

.loginFooterBall{
    width: 200px;
    position: fixed;
    right: -80px;
    bottom: 0;
}
.login input, .register input, select{
    background-color: #EBF1FE; 
    color: #000066;
    font-weight: bold;
    border-radius: 8px;
}
.login input::placeholder, select::placeholder{
    color: #BEBEFF;
}


/* /////////// Position page styles begin here //////////// */
.positionButton{
    cursor: pointer;
}
.positionPage.container{
   display: flex; 
   align-items: center;
}
.positionButtonsGrid{
    width: 60%;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 20px;
    justify-content: center;
    padding-bottom: 50px;
}








/* /////////////// Mobile view styles are below this line //////////////// */

@media (max-width: 768px){
    .positionButtonsGrid{
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    }
  
    .fieldBG:after{
        width: 75px;
        height: 75px;
        bottom: 0;
        right: -20px;
        padding: 20px;
    }
}